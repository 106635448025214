import React from 'react';

const PrinterIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 474.34 400" {...props}>


    <path d="M434.71,111.67H375.09V19.45C375.09,8.73,366.62,0,356.22,0H118.13C107.72,0,99.25,8.73,99.25,19.45v92.22H39.64A39.68,39.68,0,0,0,0,151.31V293.09a39.69,39.69,0,0,0,39.64,39.65H75L58.3,378.43A15,15,0,0,0,60,392.37c3.39,4.85,9.45,7.63,16.62,7.63H397.71c7.18,0,13.23-2.78,16.62-7.63A15,15,0,0,0,416,378.43l-16.7-45.69h35.37a39.69,39.69,0,0,0,39.63-39.65V151.31A39.68,39.68,0,0,0,434.71,111.67Zm-10.52,66.67a16.52,16.52,0,1,1,16.51-16.51A16.51,16.51,0,0,1,424.19,178.34ZM118,19.45a1,1,0,0,1,.26-.75H356.13a1,1,0,0,1,.26.75v92.22H118ZM77.16,381.31l38-103.92H359.21l38,103.92Z" /><path d="M319.88,300.56H154.46a9.35,9.35,0,0,0,0,18.7H319.88a9.35,9.35,0,1,0,0-18.7Z" /><path d="M328.89,341.73H145.46a9.35,9.35,0,1,0,0,18.69H328.89a9.35,9.35,0,0,0,0-18.69Z" />
  </svg>);

export default PrinterIcon;
