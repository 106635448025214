import React from 'react';

interface Props {
    fill?: string;
    onClick?: () => {}
    width?: string;
    height?: string;
}

export const NightIcon = (props: Props) => {
    return (
        <svg height="1em" viewBox="0 0 128 128" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill={props.fill ?? "#fafafa"}>
                <path d="m97.5153 90.8783c3.5757-.2483 6.2787 3.6094 3.8997 6.2905-9.1596 10.3242-22.5274 16.8312-37.415 16.8312-27.6142 0-50-22.3858-50-50 0-19.5963 11.2733-36.5595 27.6884-44.7582 3.2064-1.6014 6.2213 2.0121 5.0491 5.3991-1.7739 5.1258-2.7375 10.6299-2.7375 16.3591 0 27.6142 22.3858 50 50 50 1.1818 0 2.354-.041 3.5153-.1217z" />
                <path d="m69.9941 51.3774c.6058-.284 1.0993-.7624 1.402-1.3592l3.7196-7.3323c1.1484-2.2639 4.4144-2.1671 5.4266.1609l3.0475 7.0086c.3028.6964.8585 1.2521 1.5549 1.5549l6.5281 2.8386c2.405 1.0457 2.405 4.4566 0 5.5023l-6.5281 2.8385c-.6964.3029-1.2521.8585-1.5549 1.555l-3.0475 7.0086c-1.0122 2.3279-4.2782 2.4248-5.4266.1609l-3.7196-7.3324c-.3027-.5967-.7962-1.0751-1.402-1.3591l-6.1997-2.9063c-2.3022-1.0792-2.3022-4.3535 0-5.4327z" />
            </g>
        </svg>
    )
}