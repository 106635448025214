import React from 'react';

const BotSinghIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	        viewBox="0 0 612 792" fill="white" xmlSpace="preserve">
        <g>
            <path d="M3.3,412.1c0-0.3,0-0.6,0-0.9c1.9-17.7,11.3-26.7,21.1-34c9-6.6,23.6-4.8,33.7-7.9c1.9-0.6,3.5-1.8,4.5-3.6
                c8.1-14.1,15.2-29.4,25.4-42.1c13.7-17,33.7-24.3,55.5-24.3c108.9-0.2,217.9-0.3,326.8,0c36.7,0.1,68.4,25.7,76.6,61.5
                c1.6,6.9,3.1,10,11,10.3c30.8,1.3,51.8,22.1,52.4,53c0.8,40,0.7,80.1,0.1,120.1c-0.5,31.6-21,52.1-52.9,53.7
                c-7.1,0.4-8.9,3-10.4,9.5c-8.4,37.2-39.9,62.1-78.3,62.2c-28.5,0.1-57,0.4-85.5-0.2c-8.4-0.2-13.1,2.4-17.1,9.7
                c-13.6,24.5-27.4,49-42,72.9c-3.8,6.2-11.5,14.7-16.7,14.3c-6.4-0.5-13.8-8.3-17.8-14.8c-14.8-23.8-28.6-48.3-42.2-72.8
                c-3.8-6.9-8.3-9.4-16.1-9.2c-27.2,0.5-54.5,0.2-81.7,0.2c-44.1-0.1-72.5-22.3-83.8-65.3c0,0,0,0,0,0c-0.8-3.1-3.4-5.4-6.6-5.8
                c-5.8-0.7-11.5-1.4-17.2-2.7c-20.3-4.6-30.9-11-38.8-28.2c-0.5-1,0-11,0-12.2C3.3,507.8,3.3,460,3.3,412.1z M306.8,724.5
                c14.5-24.9,28.1-47.8,41.1-71.1c4.7-8.5,10.5-11.9,20.3-11.8c32.8,0.4,65.6,1,98.3-0.4c35.3-1.4,53.3-21.4,53.4-56.5
                c0.1-66-1.1-132.1,0.5-198.1c0.9-35.5-23.1-59.3-59.3-59c-103,0.9-206,0.3-309,0.3c-38.7,0-58.7,20.3-58.7,59.3
                c-0.1,64.3,0,128.7,0,193c0,41.1,19.8,61.2,61,61.4c30.6,0.2,61.3,0.3,91.9-0.1c9.3-0.1,15,3.1,19.4,11.1
                C278.7,676.1,292.2,699.2,306.8,724.5z"/>
            <path d="M563.7,330.6c-71.5-69.2-161.1-98.4-254.7-118.5c-71.8-15.4-144.6-22.5-217.9-23.6c-4.7-0.1-9.4-0.3-14-0.6
                c-1.2-0.1-2.4-0.5-6.4-1.5c5.3-3.4,8.7-6,12.5-7.9c72.3-35,144.6-70.1,217.2-104.4c6.9-3.3,16.7-3.9,24.2-2.1
                c69.6,16.5,135.9,41,194.2,83.7c46.1,33.8,52.1,47.6,46,104.2c0,0.2-0.7,0.4,0.9-0.5c-19.7-19.3-37.5-40-58.6-56.5
                c-55-43.3-119.6-65.5-187-80.4c-11.2-2.5-22.5-4.4-33.9-5.8c-3-0.4-6.3,2.1-9.4,3.3c2.6,2.1,5,5.2,8,6
                c37.7,10.2,76.2,17.9,113,30.5c54.1,18.4,102.7,46.6,141.5,90.2C560.9,271,570.9,297.5,563.7,330.6z"/>
            <path d="M372,30.9C269,78.1,165.5,125.7,62.4,173c6.6-37.5,26.2-69.1,58.4-92.8c42.9-31.5,92-46.9,143.9-54.5
                C301.3,20.3,337.6,21.7,372,30.9z"/>
            <path d="M276.1,217.1c-82.2,22.8-161.2,51.1-225.4,114.1c0.6-13.3,1-24.4,1.6-35.4c1.6-28.9,3.3-57.7,4.8-86.6
                c0.3-6.2-0.2-11.8,9.1-11.6C136.5,199.2,206.6,203.1,276.1,217.1z"/>
            <path d="M334.3,60.8c18.5-7.4,36.9-14.8,55.4-22.1c2.3-0.9,5.2-1.2,7.5-0.7c53.5,12.3,102.5,32.5,133.2,81.6
                c8.5,13.6,13.2,29.5,18.4,45.1C485.4,112.6,411.8,83.8,334.3,60.8z"/>
            <path d="M240.8,451.9c-0.2,26.7-22.2,48.7-48.8,48.7c-26.7,0.1-49.7-23.2-49.5-49.9c0.3-27.2,22.7-49.2,50.1-49
                C219.8,401.8,241,424,240.8,451.9z"/>
            <path d="M470.8,451.8c-0.2,26.7-22.2,48.7-48.8,48.8c-26.7,0.1-49.8-23.1-49.6-49.9c0.1-27.1,22.7-49.3,50-49.1
                C449.6,401.8,471,424,470.8,451.8z"/>
            <path d="M306.3,595.7c-31.4-1.7-56.3-14.7-73.3-41.7c-6.9-11-6.2-19.4,2.2-24.5c10.3-6.1,16.4-0.1,22,8
                c25.9,38.2,73.7,38.2,99.5-0.1c5.6-8.2,11.9-14.3,22-7.9c9.6,6.1,8.6,14.4,3.1,23.2C364.1,580.6,338.9,594.5,306.3,595.7z"/>
        </g>
    </svg>
    )
}

export default BotSinghIcon;