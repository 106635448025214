import React from "react";

interface Props {
    fill?: string;
    onClick?: () => {}
    width?: string;
    height?: string;
}

export const SunIcon = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 563.055 563.055" width="1em" {...props}>
            <g fill={props.fill ?? '#fafafa'}>
                <path d="m281.527 0c14.135 0 25.593 11.459 25.593 25.593v51.187c0 14.135-11.458 25.593-25.593 25.593s-25.593-11.459-25.593-25.593v-51.187c0-14.134 11.458-25.593 25.593-25.593z" />
                <path d="m281.527 139.539c-78.414 0-141.982 63.567-141.982 141.983 0 78.413 63.568 141.982 141.982 141.982 78.416 0 141.982-63.569 141.982-141.982 0-78.416-63.566-141.983-141.982-141.983z" />
                <path d="m119.252 83.052c-9.995-9.995-26.2-9.995-36.194 0-9.995 9.995-9.995 26.2 0 36.195l36.562 36.562c9.995 9.995 26.2 9.995 36.194 0 9.995-9.995 9.995-26.2 0-36.195z" /><path d="m0 281.527c0-14.135 11.459-25.593 25.593-25.593h51.187c14.135 0 25.593 11.458 25.593 25.593s-11.458 25.593-25.593 25.593h-51.187c-14.134.001-25.593-11.457-25.593-25.593z" /><path d="m155.814 443.436c9.995-9.994 9.995-26.197 0-36.194-9.995-9.994-26.2-9.994-36.194 0l-36.562 36.563c-9.995 9.994-9.995 26.2 0 36.194s26.199 9.994 36.194 0z" /><path d="m281.527 460.681c14.135 0 25.593 11.458 25.593 25.593v51.187c0 14.135-11.458 25.593-25.593 25.593s-25.593-11.458-25.593-25.593v-51.187c0-14.135 11.458-25.593 25.593-25.593z" />
                <path d="m443.436 407.242c-9.997-9.994-26.2-9.994-36.194 0-9.997 9.997-9.997 26.2 0 36.194l36.56 36.563c9.997 9.994 26.2 9.994 36.194 0 9.997-9.994 9.997-26.2 0-36.194z" /><path d="m460.681 281.527c0-14.135 11.458-25.593 25.593-25.593h51.187c14.135 0 25.593 11.458 25.593 25.593s-11.458 25.593-25.593 25.593h-51.187c-14.135.001-25.593-11.457-25.593-25.593z" /><path d="m479.997 119.246c9.997-9.995 9.997-26.2 0-36.195-9.994-9.995-26.197-9.995-36.194 0l-36.56 36.562c-9.997 9.995-9.997 26.2 0 36.195 9.994 9.995 26.197 9.995 36.194 0z" />
            </g>
        </svg>
    )
}