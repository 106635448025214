  import React from 'react';

const WriterIcon = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    width="1em"
    height="1em"
    {...props}
  >
    <g>
      <path d="M71.3,990c0-160.2,88-299.6,218.2-373.2c59.7,41.9,132.1,67,210.6,67c78.5,0,150.9-25.1,210.6-67c130.1,73.6,218.2,213,218.2,373.2H71.3z M500,622.5c-169.1,0-306.3-137.1-306.3-306.3S330.9,10,500,10c169.1,0,306.2,137.1,306.2,306.2S669.1,622.5,500,622.5z" />
    </g>
  </svg>
);

export default WriterIcon;
