import { MAX_LARIVAAR_ASSIST_STRENGTH } from './maximum';
import { SEARCH_TYPES } from "./search-types";
import { VISRAAM } from './visraam';

export const DEFAULT_SEARCH_TYPE = SEARCH_TYPES.FIRST_LETTERS;
export const DEFAULT_SEARCH_SOURCE = 'all';
export const DEFAULT_SEARCH_WRITER = 'all';
export const DEFAULT_SEARCH_WRITERS = [];
export const DEFAULT_TRANSLITERATION_LANGUAGES = ['english'];
export const DEFAULT_ENGLISH_TRANSLATION_LANGUAGES = ['BaniDB'];
export const DEFAULT_TRANSLATION_LANGUAGES = ['english'];
export const DEFAULT_STEEK_LANGUAGES = [];
export const DEFAULT_HINDI_TRANSLATION_LANGUAGES = [];
export const DEFAULT_LARIVAAR = false;
export const DEFAULT_LARIVAAR_ASSIST = false;
export const DEFAULT_UNICODE = false;
export const DEFAULT_SPLIT_VIEW = false;
export const DEFAULT_READING_MODE = false;
export const DEFAULT_FONT_SIZE = 2;
export const DEFAULT_TRANSLATION_FONT_SIZE = 1.2;
export const DEFAULT_TRANSLITERATION_FONT_SIZE = 1.2;
export const DEFAULT_LINE_HEIGHT = 1.2;
export const DEFAULT_LARIVAAR_ASSIST_STRENGTH = MAX_LARIVAAR_ASSIST_STRENGTH;
export const DEFAULT_FONT_FAMILY = 'gurmukhi_heavy';
export const DEFAULT_PAGE_TITLE = 'SikhiToTheMax';
export const DEFAULT_DARK_MODE = false;
export const DEFAULT_MAHAAN_KOSH_TOOLTIP = true;
export const DEFAULT_AUTO_SCROLL_MODE = false;
export const DEFAULT_IS_AUTOSCROLLING = false;
export const DEFAULT_PARAGRAPH_MODE = false;
export const DEFAULT_CENTER_ALIGN_GURBANI = false;
export const DEFAULT_VISRAAMS = false;
export const DEFAULT_VISRAAM_SOURCE = 'sttm';
export const DEFAULT_VISRAAM_STYLE = VISRAAM.TYPES['colored-words'];
export const DEFAULT_SEHAJ_PAATH_MODE = false;
export const DEFAULT_CARTOONIFIED_PAGES = false;
export const DEFAULT_SHABAD_AUDIO_PLAYER = true;
export const DEFAULT_SG_BAANI_LENGTH = 's'
