import React from 'react';

const ShortcutIcon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="0.7em"
    height="0.7em"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M75 631 c-45 -20 -70 -60 -70 -112 0 -65 40 -109 109 -116 l46 -6 0
-77 0 -77 -46 -6 c-69 -7 -109 -51 -109 -117 0 -43 5 -54 33 -82 28 -28 39
-33 82 -33 66 0 110 40 117 109 l6 46 77 0 77 0 6 -46 c7 -69 51 -109 117
-109 43 0 54 5 82 33 28 28 33 39 33 82 0 66 -40 110 -109 117 l-46 6 0 77 0
77 46 6 c69 7 109 51 109 117 0 43 -5 54 -33 82 -28 28 -39 33 -82 33 -43 0
-54 -5 -82 -32 -27 -27 -33 -41 -33 -78 l0 -45 -85 0 -85 0 0 45 c0 37 -6 51
-31 76 -33 33 -92 46 -129 30z m75 -86 c15 -18 10 -45 -12 -59 -35 -22 -74 27
-48 59 16 19 44 19 60 0z m400 0 c26 -32 -13 -81 -47 -59 -23 14 -28 41 -13
59 16 19 44 19 60 0z m-150 -225 l0 -80 -80 0 -80 0 0 80 0 80 80 0 80 0 0
-80z m-250 -175 c26 -32 -13 -81 -47 -59 -10 6 -19 19 -21 28 -8 38 43 61 68
31z m400 0 c26 -32 -13 -81 -47 -59 -23 14 -28 41 -13 59 16 19 44 19 60 0z"
      />
    </g>
  </svg>
);
export default ShortcutIcon;
